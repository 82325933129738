import { useReducer } from 'react';
import type { AxiosRequestHeaders } from 'axios';
import {
  API_ENDPOINTS,
  API_SERVICE_URLS,
  formatHttpError,
  formatHttpResponse,
  http,
} from '@flowardco/flib-helpers';

export interface HeadersProps extends AxiosRequestHeaders {
  lang: number;
  OpsCountryID: number;
  opsId: number;
  currency: string;
}

export const LOADING_POSTAL_CODES = 'LOADING_POSTAL_CODES';
export const LOAD_POSTAL_CODES = 'LOAD_POSTAL_CODES';
export const VALIDATE_POSTAL_CODES = 'VALIDATE_POSTAL_CODES';

export interface PostalCodeHookProps {
  headers?: HeadersProps;
  onPostalCodeCheckError?: (error: any) => void;
  onPostalCodeCheckSuccess?: (res: any) => void;
}

export interface PostalCodeHookResponseProps {
  getUKPostalCodes: (key: string, container?: string) => Promise<any>;
  checkPostalCodeDelivery: (
    addressId: string,
    val: string,
    londonOnly?: boolean,
    additionalParams?: any
  ) => Promise<any>;
  state: PostalCodeStateProps;
  dispatch: React.Dispatch<any>;
}

export interface PostalCodeStateProps {
  isLoading: boolean;
  postalCodes: any[];
}

export interface PostalCodeActionsProps {
  type: string;
  payload?: any;
}

export const defaultPostalCodeState: PostalCodeStateProps = {
  isLoading: false,
  postalCodes: [],
};

export const postalCodeReducer = (
  state: PostalCodeStateProps,
  action: PostalCodeActionsProps
): PostalCodeStateProps => {
  switch (action.type) {
    case LOADING_POSTAL_CODES:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_POSTAL_CODES:
      return {
        ...state,
        isLoading: false,
        postalCodes: action.payload,
      };
    case VALIDATE_POSTAL_CODES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const usePostalApi = ({
  headers = { lang: 1, OpsCountryID: 8, opsId: 10, currency: 'GBP' },
  onPostalCodeCheckError,
  onPostalCodeCheckSuccess,
}: PostalCodeHookProps): PostalCodeHookResponseProps => {
  const kongOrderV1Client = http({
    baseURL: API_SERVICE_URLS.KONG_ORDERV1,
    headers,
  });

  const [state, dispatch] = useReducer(
    postalCodeReducer,
    defaultPostalCodeState
  );

  const getUKPostalCodes = async (
    key: string,
    container: string = ''
  ): Promise<any> => {
    dispatch({
      type: LOADING_POSTAL_CODES,
    });
    try {
      const data = await kongOrderV1Client.get(
        API_ENDPOINTS.orderV1.getPostalCodes,
        {
          params: { key, container },
        }
      );
      const formattedResponse = formatHttpResponse(data);
      dispatch({
        type: LOAD_POSTAL_CODES,
        payload: formattedResponse?.Items || [],
      });
      return formattedResponse;
    } catch (error: any) {
      dispatch({
        type: LOAD_POSTAL_CODES,
        payload: [],
      });
      return formatHttpError(error);
    }
  };

  const checkPostalCodeDelivery = async (
    addressId: string,
    postalcode: string,
    londonOnly: boolean = false,
    additionalParams: any = {}
  ): Promise<any> => {
    dispatch({
      type: VALIDATE_POSTAL_CODES,
      payload: { isLoading: true },
    });
    try {
      const data = await kongOrderV1Client.get(
        API_ENDPOINTS.orderV1.checkPostalCodeDelivery,
        {
          params: { addressId, postalcode, londonOnly, ...additionalParams },
        }
      );
      const formattedResponse = formatHttpResponse(data);
      if (onPostalCodeCheckSuccess) {
        onPostalCodeCheckSuccess(formattedResponse);
      }
      dispatch({
        type: VALIDATE_POSTAL_CODES,
        payload: { isLoading: false },
      });
      return formattedResponse;
    } catch (error: any) {
      if (onPostalCodeCheckError) {
        onPostalCodeCheckError(error);
      }
      dispatch({
        type: VALIDATE_POSTAL_CODES,
        payload: { isLoading: false },
      });
      return formatHttpError(error);
    }
  };

  return {
    getUKPostalCodes,
    checkPostalCodeDelivery,
    state,
    dispatch,
  };
};

export default usePostalApi;
