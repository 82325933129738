import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';

import {
  API_ENDPOINTS,
  formatUrlParams,
  formatHttpResponse,
} from '@flowardco/flib-helpers';

import { addRequestHeaders, kongTicketingClient } from './api.service';
import type { LocalAppContextProps } from '@/Context/LocalAppContext';
import { addToast } from '@flowardco/flib-util';
import { type TicketPriority } from '@flowardco/flib-models';

export interface ComplaintValues {
  topicId: string;
  title: string;
  topicName: string;
  description: string;
  mapid?: number;
  openedBy?: number;
  openedByName: string;
  opsName?: string;
  opsId?: number;
  file1: any;
  ticketPriority: TicketPriority;
  countryId?: number;
  countryName?: string;
}
const formatOrderIssuesResponse = (data: any) => {
  return {
    data: data?.map((item: any) => ({
      ...item,
      value: item?.id,
      label: item?.title,
    })),
  };
};
export const getOrderTickets = async (appState: LocalAppContextProps) => {
  try {
    const { orderData, userToken, lang } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const res = await kongTicketingClient.get(
      formatUrlParams(API_ENDPOINTS.ticketing.helpCenterApp.getOrderTickets, {
        orderId: appState?.orderData?.order?.id,
      }),
      {
        headers,
      }
    );
    const orderTicketsData = formatHttpResponse(res);
    return orderTicketsData;
  } catch (error: any) {
    errorLoggerV2('OrderIssueService::getOrderTickets', error);
    return [];
  }
};
export const getOrderIssues = async (appState: LocalAppContextProps) => {
  try {
    const { orderData, userToken, lang } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const res = await kongTicketingClient.get(
      API_ENDPOINTS.ticketing.helpCenterApp.getOrderIssues,
      {
        headers,
      }
    );
    const orderIssuesData = formatHttpResponse(res);
    return formatOrderIssuesResponse(orderIssuesData || [])?.data;
  } catch (error: any) {
    errorLoggerV2('OrderIssueService::getOrderIssues', error);
    return [];
  }
};

export const raiseComplaint = async (
  values: any,
  appState: LocalAppContextProps,
  __T: any
): Promise<any> => {
  try {
    const { orderData, userToken, lang } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const data = await kongTicketingClient.post(
      API_ENDPOINTS.ticketing.helpCenterApp.raiseComplaint,
      values,
      {
        headers,
      }
    );
    const formattedResponse = formatHttpResponse(data);
    if (formattedResponse) {
      addToast(
        __T(
          'Ticket created. So sorry for the issue you faced! Our customer service team will contact you as soon as possible. We always resolve issues within 24 hours.'
        )
      );
    }
    return formattedResponse;
  } catch (error: any) {
    addToast(error?.response?.data?.message, 'error');
    errorLoggerV2('OrderIssueService::raiseComplaint', error);
    return false;
  }
};
